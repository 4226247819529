import cx from 'classnames';
import { Grid } from 'semantic-ui-react';
import React from 'react';
import CircleIcon from './CircleIcon';

type SkillProps = {
  description: React.ReactNode;
  header: string;
  icon: JSX.Element;
}

const Skill = ({
  description,
  header,
  icon,
}: SkillProps): JSX.Element => (
  <Grid.Column width={5}>
    <div
      className={cx([
        'u-margin-left-xs',
        'u-margin-right-xs',
        'u-text-center',
      ])}
    >
      <div
        className={cx([
          'u-flex',
          'u-flex-row',
          'u-justify-content-center',
        ])}
      >
        <div
          className={cx([
            'u-align-items-center',
            'u-flex',
            'u-flex-row',
            'u-justify-content-center',
          ])}
          style={{
            height: '75px',
            width: '75px',
          }}
        >
          <CircleIcon
            className="u-position-absolute"
            fill="white"
            height={75}
          />
          { icon }
        </div>
      </div>
      <p
        className={cx([
          'u-color-white',
          'u-fontNormal',
          'u-font-size-s',
          'u-line-height-s',
          'u-margin-bottom-3xs',
          'u-margin-top-s',
          'u-text-center',
        ])}
      >
        <strong className="text">{ header }</strong>
      </p>
      <p
        className={cx([
          'u-color-white',
          'u-font-size-xs',
          'u-line-height-m',
          'u-margin-top-2xs',
          'u-text-center',
          'u-text-normal',
        ])}
      >
        { description }
      </p>
    </div>
  </Grid.Column>
);

export default Skill;
