import cx from 'classnames';
import { Grid } from 'semantic-ui-react';
import React from 'react';
import Job from './Job';
import Section from '../Section';

const Portfolio = (): JSX.Element => (
  <Section
    className="u-background-color-white"
    id="Portfolio"
  >
    <Grid
      centered
      columns={1}
      padded="horizontally"
    >
      <Grid.Row>
        <Grid.Column>
          <p
            className={cx([
              'u-color-text',
              'u-font-size-l',
              'u-line-height-s',
              'u-margin-bottom-3xs',
              'u-text-center',
              'u-text-normal',
            ])}
          >
            Portfolio
          </p>
          <hr
            className={cx([
              'u-border-color-text',
              'u-horizontal-rule',
              'u-margin-bottom-0',
            ])}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid
        centered
        columns={3}
        doubling
        padding="horizontally"
        stackable
      >
        <Grid.Row>
          <Grid.Column width={4}>
            <Job
              company="New Relic"
              image="portfolio-newrelic"
              position="Senior Software Developer"
              // https://newrelic.com/blog/nerdlog/new-capabilities-proactive-detection/
              url="https://newrelic.com/platform/applied-intelligence/features#toc-smarter-detection/"
            />
            <Job
              company="Walc, Inc."
              image="portfolio-walc"
              position="Solutions Architect & Software Engineer"
              // url="https://www.walc.me/"
              url={undefined}
            />
            <Job
              company="The HomeSource Corp"
              image="portfolio-hansgrohe"
              position="Software Developer"
              // url="http://hansgrohe.thehomesourceapp.com/"
              url={undefined}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Job
              company="Meowing Rooster, LLC."
              image="portfolio-mr"
              position="Co-Founder & CTO"
              // url="http://meowingrooster.com/"
              url={undefined}
            />
            <Job
              company="SketchFactor"
              image="portfolio-sketchfactor"
              position="Mobile Application Developer"
              url="https://www.newyorker.com/business/currency/what-to-do-when-your-app-is-racist/"
            />
            <Job
              company="The HomeSource Corp"
              image="portfolio-homesource"
              position="Software Developer"
              // url="http://web.thehomesourceapp.com/"
              url={undefined}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Job
              company="Square One Mail, Inc."
              image="portfolio-gong"
              position="iOS Engineer"
              url="https://techcrunch.com/2016/07/15/immediately-shuts-down/"
            />
            <Job
              company="The HomeSource Corp"
              image="portfolio-brandsource"
              position="Software Developer"
              // url="http://www.brandsourcemobile.com/"
              url={undefined}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Grid>
  </Section>
);

export default React.memo(Portfolio);
