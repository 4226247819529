import cx from 'classnames';
import { Grid } from 'semantic-ui-react';
import React from 'react';
import Section from '../Section';

const About = (): JSX.Element => (
  <Section
    className="u-background-color-white"
    id="About"
  >
    <Grid
      centered
      columns={2}
      padded="horizontally"
    >
      <Grid.Row>
        <Grid.Column width="10">
          <p
            className={cx([
              'u-color-text',
              'u-font-size-m',
              'u-line-height-s',
              'u-text-normal',
              'u-text-center',
            ])}
          >
            <b>About me</b>
            <br />
            <br />
            Are you in need of a front end developer? Known for my innovative
            ideas, detail-oriented approach, and fast turnaround, I&apos;m looking to help you
            build your application and add to my portfolio.
            <br />
            <br />
            My love for software development began at Drexel University, where I obtained an
            undergraduate degree in computer engineering. My educational pursuits continued when I
            completed a master&apos;s degree in software engineering at Penn State
            University while working full-time.
            <br />
            <br />
            After completing my undergraduate degree, I quickly entered the software industry as
            a developer. I&apos;ve held various positions creating mobile and web applications at
            development firms in Washington D.C., Philadelphia, New York, and now San Francisco.
            <br />
            <br />
            My current full-time position is Senior Software Developer for New Relic. In
            addition, until 2016, I was the Co-Founder and CTO of Meowing Rooster LLC, a
            mobile software development company that we decided to close after four years
            of operation.
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Section>
);

export default React.memo(About);
