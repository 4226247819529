import cx from 'classnames';
import { Menu, Sidebar } from 'semantic-ui-react';
import React from 'react';
import { getAuth, signInAnonymously } from 'firebase/auth';
import About from '../About';
import FileCodeIcon from './FileCodeIcon';
import FolderOpenIcon from './FolderOpenIcon';
import Footer from '../Footer';
import Header from '../Header';
import MenuItem from './MenuItem';
import More from '../More';
import Portfolio from '../Portfolio';
import Skills from '../Skills';
import firebase from '../../utils/firebase';

import './styles.scss';
import HamburgerButton from './HamburgerButton';
import ArrowToTopIcon from './ArrowToTopIcon';
import InfoIcon from './InfoIcon';
import EnvelopeIcon from './EnvelopeIcon';

const Application = (): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (!firebase) return;
    // Sign in as an anonymous user
    const auth = getAuth(firebase);
    signInAnonymously(auth);
  }, []);

  const toggleMenu = (event: React.MouseEvent): void => {
    if (event) {
      event.preventDefault();
    }
    setIsOpen(!isOpen);
  };

  const onClickDimmer = (event: React.MouseEvent): void => {
    if (isOpen === true) {
      toggleMenu(event);
    }
  };

  const onClickMenuButton = (event: React.MouseEvent): void => toggleMenu(event);
  const onClickMenuItem = (event: React.MouseEvent): void => toggleMenu(event);

  return (
    <div
      className={cx([
        'personal-website-application',
        'u-height-full',
        'u-width-full',
      ])}
    >
      <HamburgerButton
        isOpen={isOpen}
        onClick={onClickMenuButton}
      />
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          direction="right"
          icon="labeled"
          inverted
          vertical
          visible={isOpen}
          width="thin"
        >
          <MenuItem
            icon={(
              <ArrowToTopIcon
                className="u-margin-bottom-3xs"
                fill="white"
                height={24}
              />
            )}
            id="header"
            onClick={onClickMenuItem}
            text="Top"
          />
          <MenuItem
            icon={(
              <InfoIcon
                className="u-margin-bottom-3xs"
                fill="white"
                height={24}
              />
            )}
            id="about"
            onClick={onClickMenuItem}
            text="About"
          />
          <MenuItem
            icon={(
              <FileCodeIcon
                className="u-margin-bottom-3xs"
                fill="white"
                height={24}
              />
            )}
            id="skills"
            onClick={onClickMenuItem}
            text="Skills"
          />
          <MenuItem
            icon={(
              <FolderOpenIcon
                className="u-margin-bottom-3xs"
                fill="white"
                height={24}
              />
            )}
            id="portfolio"
            onClick={onClickMenuItem}
            text="Portfolio"
          />
          <MenuItem
            icon={(
              <EnvelopeIcon
                className="u-margin-bottom-3xs"
                fill="white"
                height={24}
              />
            )}
            id="footer"
            onClick={onClickMenuItem}
            text="Contact"
          />
        </Sidebar>
        <Sidebar.Pusher
          dimmed={isOpen}
          onClick={onClickDimmer}
        >
          <Header />
          <About />
          <Skills />
          <Portfolio />
          <More />
          <Footer />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default React.memo(Application);
