import cx from 'classnames';
import { Grid } from 'semantic-ui-react';
import React from 'react';
import Image from './Image';
import Section from '../Section';
import UserContext from '../../utils/context';

const Header = (): JSX.Element => {
  const renderImage = (): JSX.Element => {
    const date = new Date();
    const day = date.getDay();
    let filename;
    switch (day) {
      // Sunday
      // Tuesday
      // Thursday
      // Saturday
      case 0:
      case 2:
      case 4:
      case 6:
        filename = 'headshot-three';
        break;
      // Monday
      // Wednesday
      // Friday
      case 1:
      case 3:
      case 5:
        filename = 'headshot-three';
        break;
      default:
        filename = 'headshot-three';
        break;
    }
    return (
      <Image filename={filename} />
    );
  };

  const value = React.useContext(UserContext);

  return (
    <Section
      className="u-background-color-blue"
      id="Header"
    >
      <Grid
        centered
        columns={1}
      >
        <Grid.Row>
          <Grid.Column>
            <p
              className={cx([
                'u-color-white',
                'u-font-size-xl',
                'u-line-height-s',
                'u-margin-bottom-3xs',
                'u-margin-top-xs',
                'u-text-bold',
                'u-text-center',
              ])}
            >
              { value.name.toUpperCase() }
            </p>
            <p
              className={cx([
                'u-color-white',
                'u-font-size-m',
                'u-line-height-s',
                'u-margin-bottom-3xs',
                'u-margin-top-xs',
                'u-text-center',
                'u-text-normal',
              ])}
            >
              Software Developer &amp; Technical Lead
            </p>
            { renderImage() }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  );
};

export default React.memo(Header);
