import { initializeApp } from 'firebase/app';

export const firebaseConfig = {
  apiKey: 'AIzaSyA9AWV9entWc-yCUmofeCXF40F7_A3eX8I',
  authDomain: 'christopher-dehaan-website.firebaseapp.com',
  databaseURL: 'https://christopher-dehaan-website.firebaseio.com',
  projectId: 'christopher-dehaan-website',
  storageBucket: 'christopher-dehaan-website.appspot.com',
  messagingSenderId: '649762710909',
  appId: '1:649762710909:web:b7bac3853eea458427d814',
};

export default initializeApp(firebaseConfig);
