import cx from 'classnames';
import { Menu } from 'semantic-ui-react';
import React from 'react';
import { scroller } from 'react-scroll';

type MenuItemProps = {
  icon: JSX.Element;
  id: string;
  onClick: (event: React.MouseEvent) => void;
  text: string;
}

const MenuItem = ({
  icon,
  id,
  onClick,
  text,
}: MenuItemProps): JSX.Element => {
  const onClickItem = (event: React.MouseEvent): void => {
    onClick(event);
    if (event.currentTarget.id) {
      const { currentTarget } = event;
      const element = currentTarget.id.charAt(0).toUpperCase() + currentTarget.id.slice(1);
      scroller.scrollTo(element, {
        delay: 0,
        duration: 1500,
        smooth: true,
      });
    }
  };

  return (
    <Menu.Item
      as="a"
      href="#"
      id={id}
      onClick={onClickItem}
    >
      <div
        className={cx([
          'u-align-items-center',
          'u-flex',
          ' u-flex-column',
          'u-justify-content-center',
        ])}
      >
        { icon }
        { text }
      </div>
    </Menu.Item>
  );
};

export default React.memo(MenuItem);
