import cx from 'classnames';
import { Image } from 'semantic-ui-react';
import React from 'react';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import firebase, { firebaseConfig } from '../../utils/firebase';

type JobProps = {
  company: string;
  image: string;
  position: string;
  url?: string;
}

const Job = ({
  company,
  image,
  position,
  url,
}: JobProps): JSX.Element => {
  const [imageSrc, setImageSrc] = React.useState<undefined | string>(undefined);
  const [isShowingJobDetail, setIsShowingJobDetails] = React.useState(false);

  const fetchImageSrc = (): void => {
    if (!firebase) return;
    // Fetch image src
    const storage = getStorage(firebase);
    const imageRef = ref(storage, `gs://${firebaseConfig.storageBucket}/assets/${image}.png`);
    getDownloadURL(imageRef).then((src: string) => {
      setImageSrc(src);
      return null;
    }).catch(() => {
      // Handle any errors
    });
  };
  // Component did mount
  React.useEffect(() => {
    fetchImageSrc();
  }, []);
  // Fetch image on authentication change
  if (firebase) {
    getAuth(firebase).onAuthStateChanged((user) => {
      if (user) fetchImageSrc();
    });
  }

  const onMouseEnter = (): void => setIsShowingJobDetails(true);
  const onMouseLeave = (): void => setIsShowingJobDetails(false);

  return (
    <div
      className={cx([
        'personal-website-Portfolio-job',
        'u-margin-bottom-m',
        'u-position-relative',
      ])}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {
        (imageSrc) && (
          <>
            <Image
              as="a"
              className={cx(
                isShowingJobDetail && 'u-opacity-percent-20',
              )}
              href={url}
              src={imageSrc}
              target="_blank"
            />
            <p
              className={cx(
                [
                  'u-bottom-xs',
                  'u-opacity-0',
                  'u-position-absolute',
                  'u-text-bold',
                  'u-text-center',
                  'u-width-full',
                ],
                isShowingJobDetail && 'u-opacity-1',
              )}
            >
              { company }
            </p>
            <p
              className={cx(
                [
                  'personal-website-Portfolio-position',
                  'u-bottom-2xs',
                  'u-opacity-0',
                  'u-position-absolute',
                  'u-text-center',
                  'u-width-full',
                ],
                isShowingJobDetail && 'u-opacity-1',
              )}
            >
              { position }
            </p>
          </>
        )
      }
    </div>
  );
};

export default React.memo(Job);
