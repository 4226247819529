import cx from 'classnames';
import { Grid, List } from 'semantic-ui-react';
import React from 'react';
import GitHubIcon from './GitHubIcon';
import LinkedInIcon from './LinkedInIcon';
import Section from '../Section';
import UserContext from '../../utils/context';

const Footer = (): JSX.Element => {
  const value = React.useContext(UserContext);
  const listeItemContentStyle = {
    width: 'inherit',
  };

  return (
    <Section
      className="u-background-color-white"
      id="Footer"
    >
      <Grid
        centered
        columns={1}
        padded="horizontally"
      >
        <Grid.Row>
          <Grid.Column>
            <p
              className={cx([
                'u-color-text',
                'u-font-size-s',
                'u-margin-bottom-4xs',
                'u-text-bold',
                'u-text-center',
              ])}
            >
              { value.name }
            </p>
            <p
              className={cx([
                'u-color-text',
                'u-font-size-xs',
                'u-margin-bottom-0',
                'u-margin-top-0',
                'u-text-center',
              ])}
            >
              { value.location }
            </p>
            <List
              className={cx([
                'u-margin-top-2xs',
                'u-text-center',
                'u-width-full',
              ])}
            >
              <List.Item className="u-display-inline">
                <List.Content
                  className="u-font-size-xs"
                  style={listeItemContentStyle}
                >
                  { value.phone }
                </List.Content>
              </List.Item>
              <List.Item
                className="u-display-inline"
              >
                <List.Content
                  className="u-font-size-xs u-margin-top-4xs"
                  style={listeItemContentStyle}
                >
                  <a href="mailto:contact@christopherdehaan.me">
                    { value.email }
                  </a>
                </List.Content>
              </List.Item>
            </List>
            <List
              className={cx([
                'u-margin-top-2xs',
                'u-text-center',
                'u-width-full',
              ])}
              horizontal
            >
              <List.Item>
                <a href="https://github.com/chrisdhaan">
                  <GitHubIcon
                    fill="rgb(35, 72, 94)"
                    height={42}
                  />
                </a>
              </List.Item>
              <List.Item>
                <a href="https://www.linkedin.com/pub/christopher-de-haan/17/755/932">
                  <LinkedInIcon
                    fill="rgb(35, 72, 94)"
                    height={42}
                  />
                </a>
              </List.Item>
            </List>
            <hr
              className={cx([
                'u-horizontal-rule',
                'u-margin-bottom-s',
                'u-margin-top-2xs',
              ])}
            />
            <p
              className={cx([
                'u-color-copyright',
                'u-text-center',
              ])}
            >
              Copyright &copy;&nbsp;
              { value.name }
              &nbsp;2015-2022
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  );
};

export default React.memo(Footer);
