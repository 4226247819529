// NPM Modules
import React from 'react';

type CircleIconProps = {
  className?: string;
  fill?: string;
  height: number;
}

const CircleIcon = ({
  className,
  fill,
  height,
}: CircleIconProps): JSX.Element => (
  <svg
    className={className}
    fill={fill}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
    />
  </svg>
);

export default React.memo(CircleIcon);
