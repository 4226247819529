import * as React from 'react';

const UserContext = React.createContext({
  email: 'contact@christopherdehaan.me',
  location: 'San Francisco, CA',
  name: 'Christopher de Haan',
  phone: '(415) 857-0427',
});

export default UserContext;
