import cx from 'classnames';
import { Grid } from 'semantic-ui-react';
import React from 'react';
import Section from '../Section';

const More = (): JSX.Element => (
  <Section
    className="u-background-color-blue"
    id="More"
  >
    <Grid
      centered
      columns={2}
      padded="horizontally"
    >
      <Grid.Row>
        <Grid.Column width="10">
          <p
            className={cx([
              'u-color-white',
              'u-font-size-m',
              'u-line-height-s',
              'u-text-normal',
              'u-text-center',
            ])}
          >
            <b>A little more</b>
            <br />
            <br />
            I&apos;m an avid learner and am always looking for opportunities to acquire new
            skills. Like meditating -- I&apos;ve been actively practicing for three years -- and
            mastering the Spanish language.
            <br />
            <br />
            In my free time, I travel. I have been to twelve countries in the past four
            years.
            <br />
            <br />
            Want to hear about those trips?
            <br />
            <br />
            You&apos;ll have to contact me.
            <br />
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Section>
);

export default React.memo(More);
