import cx from 'classnames';
import { Grid } from 'semantic-ui-react';
import React from 'react';
import CodeIcon from './CodeIcon';
import Section from '../Section';
import Skill from './Skill';
import SuitcaseIcon from './SuitcaseIcon';
import TabletIcon from './TabletIcon';

const Skills = (): JSX.Element => (
  <Section
    className="u-background-color-blue"
    id="Skills"
  >
    <Grid
      centered
      columns={1}
      padded="horizontally"
    >
      <Grid.Row>
        <Grid.Column>
          <p
            className={cx([
              'u-color-white',
              'u-font-size-l',
              'u-line-height-s',
              'u-margin-bottom-3xs',
              'u-text-center',
              'u-text-normal',
            ])}
          >
            Skills
          </p>
          <hr
            className={cx([
              'u-border-color-white',
              'u-horizontal-rule',
              'u-margin-bottom-0',
            ])}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid
        centered
        columns={3}
        doubling
        padding="horizontally"
        stackable
      >
        <Grid.Row>
          <Skill
            description={(
              <>
                Time is money. Building&nbsp;
                <a
                  className="u-color-white"
                  href="https://en.wikipedia.org/wiki/Minimum_viable_product"
                >
                  <i>minimal viable products</i>
                </a>
                &nbsp;for start-up companies is my passion. I help entrepreneurs get their ideas
                off the ground and into the space as quickly, elegantly, and effectively
                as possible.
              </>
            )}
            header="MINIMUM VIABLE PRODUCTS"
            icon={(
              <TabletIcon
                className="u-position-absolute"
                fill="rgb(35, 72, 94)"
                height={45}
              />
            )}
          />
          <Skill
            description="Front end development is a hobby and a lifestyle. Having amassed years of knowledge in the field, I develop software for myself and for companies who are looking to change the world one small app at a time."
            header="MOBILE & WEB DEVELOPMENT"
            icon={(
              <CodeIcon
                className="u-position-relative"
                fill="rgb(35, 72, 94)"
                height={45}
              />
            )}
          />
          <Skill
            description="With deep industry experience, I help companies determine best fit paths for products, new product development, and product release roadmaps in the form of consumer and technical perspectives."
            header="TECHNICAL CONSULTING"
            icon={(
              <SuitcaseIcon
                className="u-position-relative"
                fill="rgb(35, 72, 94)"
                height={45}
              />
            )}
          />
        </Grid.Row>
      </Grid>
    </Grid>
  </Section>
);

export default React.memo(Skills);
